/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~assets/scss/fonts.scss';
@import '~assets/scss/variables.scss';

.montserrat-bold
{
    font-family: Montserrat-Bold;
}

.montserrat-semibold
{
    font-family: Montserrat-SemiBold;
}

.montserrat-medium
{
    font-family: Montserrat-Medium;
}

.montserrat-italic
{
    font-family: Montserrat-Italic;
}

.montserrat-regular
{
    font-family: Montserrat-Regular;
}

.fs-16
{
    font-size: 16px;
}

.fs-12
{
    font-size: 12px;
}

.fs-20
{
    font-size: 20px;
}

.fs-11
{
    font-size: 11px;
}

.p-blue
{
    color: $p-blue;
}

.s-black
{
    color: $s-black;
}

.s-blue
{
    color: $s-blue;
}

.primary-button
{
    height: 48px !important;
    width: 176px !important;
    border-radius: 0 !important;
    color: $white !important;
    background-color: $p-blue !important;
    margin-top: 32px !important;
    margin-bottom: 160px !important;

    &:hover
    {
        background-color: $white !important;
        color: $p-blue !important;
        border: 1px solid $p-blue !important;
    }
}

.secundary-button
{
    height: 48px !important;
    width: 176px !important;
    border-radius: 0 !important;
    color: $white !important;
    background-color: $s-blue !important;
    margin-top: 32px !important;
    margin-bottom: 160px !important;
    
    &:hover
    {
        background-color: $white !important;
        color: $s-blue !important;
        border: 1px solid $s-blue !important;
    }
}

.custom-modal-background 
{
    background-color: $p-blue !important;
}

// cookieconsent configuration
#s-inr
{
    border-radius: 0;
    font-family: Montserrat-Regular;
}

#s-c-bn
{
    background-color: $white;
}

#cm
{
    border-radius: 0;
}

.c-bn
{
    border-radius: 0 !important;
    width: 176px;
    height: 48px;
}

#c-bns
{
    flex-direction: column;
    align-items: center;
}

#c-p-bn
{
    background-color: $p-blue !important;
    border: 1px solid $p-blue;

    &:hover
    {
        border: 1px solid $p-blue;
        color: $p-blue !important;
        background-color: $white !important;
    }
}

#c-s-bn
{
    margin-left: 0 !important;
    margin-top: 8px;
    color: $p-blue;
    background-color: $white;
    text-decoration: underline;
    border: 1px solid $white;

    &:hover
    {
        border: 1px solid $p-blue;
        background-color: $white !important;
    }
}

.c-bl
{
    border-radius: 0 !important;
    color: $p-blue;
}

#s-all-bn
{
    float: right;
    background-color: $p-blue !important;
    border: 1px solid $p-blue;

    &:hover
    {
        border: 1px solid $p-blue;
        color: $p-blue !important;
        background-color: $white !important;
    }
}

#s-sv-bn
{
    float: left !important;
    background-color: $white !important;
    border: 1px solid $white;
    text-decoration: underline;

    &:hover
    {
        border: 1px solid $p-blue;
        background-color: $white !important;
    }
}

#c-inr
{
    font-family: Montserrat-Regular;
}

#s-hdr
{
    border-bottom: 0;
}

#c-txt
{
    color: $s-black;
}

.cc-link
{
    color: $p-blue !important;
}

#s-ttl
{
    color: $p-blue;
}

#s-c-bn:hover
{
    background-color: $p-blue-15;
}

.c-bl.b-ex
{
    background-color: $white !important;
}

.b-8
{
    bottom: 8px;
}

.r-16
{
    right: 16px;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
