@font-face
{
    font-family: Montserrat-Bold;
    src: url('~assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face
{
    font-family: Montserrat-BoldItalic;
    src: url('~assets/fonts/Montserrat/Montserrat-BoldItalic.ttf');
}

@font-face
{
    font-family: Montserrat-Italic;
    src: url('~assets/fonts/Montserrat/Montserrat-Italic.ttf');
}

@font-face
{
    font-family: Montserrat-Medium;
    src: url('~assets/fonts/Montserrat/Montserrat-Medium.ttf');
}

@font-face
{
    font-family: Montserrat-MediumItalic;
    src: url('~assets/fonts/Montserrat/Montserrat-MediumItalic.ttf');
}

@font-face
{
    font-family: Montserrat-Regular;
    src: url('~assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face
{
    font-family: Montserrat-SemiBold;
    src: url('~assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

@font-face
{
    font-family: Montserrat-SemiBoldItalic;
    src: url('~assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
}
