$success: #00980e;
$header: #08aac8;
$primary-color: #082f43;
$title: #668da0;
$error: #d70000;
$warning: #f5a623;
$white: #fff;
$light-black: #202020;
$gray: #F6F6F6;
$white-grey: #eef7fc;
$dark-blue: #137aaa;
$p-blue: #052478;
$p-blue-50: #9ca7c1;
$p-blue-15: #e9ebf1;
$s-blue: #2f7cba;
$s-blue-50: #89b0cf;
$s-blue-15: #cad6df;
$s-black: #101010;
$s-black-50: #878787;
$s-black-15: #dbdbdb;
$aux-pink: #c11e67;
$aux-pink-50: #d281a5;
$aux-pink-15: #f6e2eb;
$chart-1: #1B9DD8;
$chart-2: #EB7E23;
$chart-3: #61B346;
$chart-4: #C11E67;
$chart-5: #176596;
$chart-6: #F8BA14;
$chart-7: #0F6B37;
